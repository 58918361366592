import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "@/assets/css/main.css";

const app = createApp(App);
app.provide("domain", process.env.VUE_APP_BASEURL);

import { VueClipboard } from "@soerenmartius/vue3-clipboard";
app.use(VueClipboard);

import setupInterceptors from "./services/setupInterceptors";
setupInterceptors(store);

import TokenService from "./services/token.service";

// client
// TokenService.updateLocalAccessToken("5X8TBtzOT_hzMuZ8Oi-D1fPP-mYmdEv5OkLqR834");

// buyer
// TokenService.updateLocalAccessToken("SfpQzkITxp8A_usn2K_XJJmkkL2yAbSzET6ziv2T");

// exit
// TokenService.updateLocalAccessToken("");
// AuthService.logout();

// if (DEBUG) {
//     const cors = require('cors');
//     const corsOptions = {
//         origin: 'http://localhost:3000',
//         credentials: true,            //access-control-allow-credentials:true
//         optionSuccessStatus: 200
//     }
//     app.use(cors(corsOptions))
// }

console.log("Mode:", process.env.NODE_ENV);
console.log("Domain:", process.env.VUE_APP_BASEURL);

app.use(store).use(router).use(Antd).mount("#app");
