import axiosInstance from "./api";
import TokenService from "./token.service";
import router from "@/router";
import { notification } from "ant-design-vue";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["X-DeliveryCHClient"] = "True";
      const token = TokenService.getLocalAccessToken();

      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        config.headers["Device-Locale"] = "ru"; // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      // console.log("originalConfig", originalConfig.url);

      let is401 = false;
      let is422 = false;

      if (
        !originalConfig.url.includes("/user/logout") &&
        !originalConfig.url.includes("/user/sign-in") &&
        err.response
      ) {
        if (err.response.status === 422) {
          is422 = true;
        }

        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          // originalConfig._retry = true;
          // try {
          //     const rs = await axiosInstance.post("/auth/refreshtoken", {
          //         refreshToken: TokenService.getLocalRefreshToken(),
          //     });
          //     const { accessToken } = rs.data;
          //     store.dispatch('auth/refreshToken', accessToken);
          //     TokenService.updateLocalAccessToken(accessToken);
          //     return axiosInstance(originalConfig);
          // } catch (_error) {
          //     return Promise.reject(_error);
          // }

          is401 = true;
          store.dispatch("auth/logout");
          router.replace("/login");
        }
      }

      if (is401 === false && is422 === false) {
        try {
          notification.error({
            message: "Ошибка сервера",
            description: err.message,
          });
        } catch (error) {
          //
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
