import UserService from "@/services/user.service";

export const userList = {
  namespaced: true,
  state: () => ({
    users: [],
    page: 1,
    isLoading: false,
    isFinished: false,
    // selectedSort: "",
    searchQuery: "",
    type: "client",
    // limit: 10,
    // totalPages: 0,
    // sortOptions: [
    //   { value: "title", name: "По названию" },
    //   { value: "body", name: "По содержимому" },
    // ],
  }),
  getters: {
    // sortedPosts(state) {
    //     return [...state.posts].sort((post1, post2) => post1[state.selectedSort]?.localeCompare(post2[state.selectedSort]))
    // },
    // sortedAndSearchedPosts(state, getters) {
    //     return getters.sortedPosts.filter(post => post.title.toLowerCase().includes(state.searchQuery.toLowerCase()))
    // }
    testData(state) {
      return true;
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    updateUser(state, item) {
      console.log("updateUser ...");

      for (let index in state.users) {
        if (state.users[index].id == item.id) {
          state.users[index] = item;
          break;
        }
      }
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    },
    setFinished(state, bool) {
      state.isFinished = bool;
    },
    setPage(state, page) {
      state.page = page;
    },

    // setSelectedSort(state, selectedSort) {
    //   state.selectedSort = selectedSort;
    // },
    // setTotalPages(state, totalPages) {
    //   state.totalPages = totalPages;
    // },
    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
    setType(state, type) {
      state.type = type;
    },
  },

  actions: {
    async initData({ state, commit }) {
      console.log("initData ...");
      commit("setLoading", false);
      commit("setFinished", false);
      commit("setPage", 1);
      commit("setUsers", []);
    },

    async loadMoreData({ state, commit }, callbacks) {
      // callbacks // onLoaded, onFinished, onError
      console.log("loadMoreData calbacks:", callbacks);

      try {
        commit("setLoading", true);
        const response = await UserService.items(
          state.page,
          state.searchQuery,
          state.type
        );

        commit("setPage", state.page + 1);
        commit("setUsers", [...state.users, ...response.data]);

        if (
          response.headers["x-pagination-current-page"] >=
          response.headers["x-pagination-page-count"]
        ) {
          commit("setFinished", true);
          console.log("Finished list");

          if (
            callbacks !== undefined &&
            "onFinished" in callbacks &&
            callbacks.onFinished !== null
          ) {
            callbacks.onFinished();
          }
        } else {
          console.log("Loaded list");

          if (
            callbacks !== undefined &&
            "onLoaded" in callbacks &&
            callbacks.onLoaded !== null
          ) {
            callbacks.onLoaded();
          }
        }
      } catch (e) {
        console.log(e);

        if (
          callbacks !== undefined &&
          "onError" in callbacks &&
          callbacks.onError !== null
        ) {
          callbacks.onError(e);
        }
      } finally {
        commit("setLoading", false);
      }
    },

    async reloadList({ state, commit, dispatch }, callbacks) {
      await dispatch("initData");
      await dispatch("loadMoreData", callbacks);
    },
  },
};
