import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, form) {
      return AuthService.login(form.email, form.password).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit("logout");
      AuthService.logout();
    },
    //   register({ commit }, user) {
    //     return AuthService.register(user).then(
    //       response => {
    //         commit('registerSuccess');
    //         return Promise.resolve(response.data);
    //       },
    //       error => {
    //         commit('registerFailure');
    //         return Promise.reject(error);
    //       }
    //     );
    //   }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  getters: {
    isClient(state) {
      try {
        if (
          state.status.loggedIn != false &&
          state.user !== null &&
          state.user.role == "client"
        ) {
          return true;
        }
      } catch ($e) {}

      return false;
    },

    isBuyer(state) {
      try {
        if (
          state.status.loggedIn != false &&
          state.user !== null &&
          state.user.role == "buyer1688"
        ) {
          return true;
        }
      } catch ($e) {}

      return false;
    },
  },
};
