import axios from "axios";
import { ref, inject } from "vue";

const instance = axios.create({
  // baseURL: "",
  // baseURL: DEBUG ? "" : DOMAIN,
  baseURL: process.env.VUE_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

export default instance;
