<template>
  <!-- <nav style="padding-top: 10px;">
    <router-link to="/">Main</router-link> |
    <router-link to="/about">About</router-link>
</a-divider> --><router-view /><!-- <a-layout>
    <a-layout-header>Header</a-layout-header>
    <a-layout-content><router-view /></a-layout-content>
    <a-layout-footer>Footer</a-layout-footer>
</a-layout> -->
</template>

<style></style>
