export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    _loadData() {
      this.isLoading = true;
      this.$emit("update:isLoading", true);
      console.log("default_loadDate method");
    },
  },
  mounted() {
    console.log("mixin loadingMixin mounted");
    this._loadData();
  },
};
