<template>
  <div v-if="isNeedLogin">...</div>
  <CenterSpin v-else-if="isLoading" title="Загрузка даных" />
</template>
<script>
import CenterSpin from "@/components/main/CenterSpin.vue";
import loadingMixin from "@/mixins/loadingMixin";
import AuthService from "@/services/auth.service";

export default {
  components: {
    CenterSpin,
  },
  mixins: [loadingMixin],
  data() {
    return {
      isNeedLogin: false,
    };
  },
  methods: {
    async _loadData() {
      this.isLoading = true;

      try {
        const response = await AuthService.auth();
        this.$router.replace("/orders");
      } catch (e) {
        console.log(e);
        this.isNeedLogin = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    loggedIn() {
      // console.log("Store auth:", this.$store.state.auth);
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
};
</script>
<style scoped></style>
