import { createStore } from "vuex";
import { auth } from "./auth.module";
import { orderList } from "./order.list.module";
import { userList } from "./user.list.module";

const store = createStore({
  modules: {
    auth,
    orderList,
    userList,
  },
});

// console.log("store: ", store);
export default store;
