import api from "./api";

class UserService {
  getPublicContent() {
    return api.get("/test/all");
  }

  getUserBoard() {
    return api.get("/test/user");
  }

  getModeratorBoard() {
    return api.get("/test/mod");
  }

  getAdminBoard() {
    return api.get("/test/admin");
  }

  items(page, searchQuery, type) {
    return api.get("/api/v1/user", {
      params: {
        page: page,
        search_query: searchQuery,
        type: type,
      },
    });
  }
}

export default new UserService();
