import api from "./api";
import TokenService from "./token.service";

class AuthService {
  async login(email, password) {
    const response = await api.post("/api/v1/user/sign-in/", {
      identity: email,
      password: password,
      from_site: 1,
    });

    if (response.data.access_token) {
      TokenService.setUser(response.data);
    }

    return response.data;
  }

  async auth() {
    const response = await api.get("/api/v1/user/auth/");

    if (response.data.access_token) {
      TokenService.setUser(response.data);
    }

    return response.data;
  }

  logout() {
    TokenService.removeUser();
    api.get("/api/v1/user/logout/");
  }

  remove() {
    TokenService.removeUser();
    api.get("/api/v1/user/remove/");
  }
}

export default new AuthService();
