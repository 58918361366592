import api from "./api";

class OrderService {
  items(page, searchQuery) {
    return api.get("/api/v1/order-1688", {
      params: {
        page: page,
        search_query: searchQuery,
      },
    });
  }

  view(id) {
    return api.get("/api/v1/order-1688/view", {
      params: {
        id: id,
      },
    });
  }

  cancel(id) {
    return api.post("/api/v1/order-1688/cancel", {
      id: id,
    });
  }

  buyerAccept(id, form) {
    return api.post("/api/v1/order-1688/buyer-accept", form, {
      params: {
        id: id,
      },
    });
  }

  clientConfirm(id) {
    return api.post(
      "/api/v1/order-1688/client-confirm",
      {},
      {
        params: {
          id: id,
        },
      }
    );
  }

  buyerBuy(id) {
    return api.post(
      "/api/v1/order-1688/buyer-buy",
      {},
      {
        params: {
          id: id,
        },
      }
    );
  }

  checkItem(id, form) {
    if ("in_stock" in form && form.in_stock === false) {
      form.in_stock = "";
    }

    return api.post("/api/v1/order-1688/check-item", form, {
      params: {
        id: id,
      },
    });
  }

  updateItem(id, form) {
    // form = {id_1688: 'asds', status: 1}
    return api.post("/api/v1/order-1688/update-item", form, {
      params: {
        id: id,
      },
    });
  }

  cancelItem(id) {
    return api.post(
      "/api/v1/order-1688/cancel-item",
      {},
      {
        params: {
          id: id,
        },
      }
    );
  }

  readItemByClient(id) {
    return api.post(
      "/api/v1/order-1688/client-read-item",
      {},
      {
        params: {
          id: id,
        },
      }
    );
  }

  logItemsForOrder(page, orderId) {
    return api.get("/api/v1/order-1688-log/index-default", {
      params: {
        page: page,
        order_id: orderId,
      },
    });
  }

  logItemsForOrderItem(page, orderItemId) {
    return api.get("/api/v1/order-1688-log/index-item", {
      params: {
        page: page,
        order_item_id: orderItemId,
      },
    });
  }

  log1688ItemsForOrderItem(page, orderId, orderItemId = null) {
    return api.get("/api/v1/order-1688-log/index-1688-default", {
      params: {
        page: page,
        order_id: orderId,
        order_item_id: orderItemId ?? "",
      },
    });
  }

  groupItemsForOrder(page, orderId, isAll = false) {
    return api.get("/api/v1/order-1688-item-group/index", {
      params: {
        page: page,
        order_id: orderId,
        is_all: isAll ? 1 : "",
      },
    });
  }

  addItemGroup(orderId, form) {
    // form = {title: 'asds', status: id_public_1688}
    return api.post("/api/v1/order-1688-item-group/add", form, {
      params: {
        order_id: orderId,
      },
    });
  }

  updateItemGroup(id, form) {
    // form = {title: 'asds', status: id_public_1688}
    return api.post("/api/v1/order-1688-item-group/update", form, {
      params: {
        id: id,
      },
    });
  }

  deleteItemGroup(id) {
    return api.post("/api/v1/order-1688-item-group/delete", {
      id: id,
    });
  }

  update1688IdForGroup(id, id1688) {
    return api.post(
      "/api/v1/order-1688-item-group/update-1688-id",
      {
        id_1688: id1688,
      },
      {
        params: {
          id: id,
        },
      }
    );
  }

  getChatGroupFroOrder(id1688) {
    return api.get("/api/v1/chat-group/get-chat-for-order-1688", {
      params: {
        order_1688_id: id1688,
      },
    });
  }

  readMessagesForChatGroup(groupId, idOrder1688Item = null) {
    return api.post("/api/v1/chat-group/read-messages", {
      group_id: groupId,
      order_1688_item_id: idOrder1688Item ?? "",
    });
  }

  getMessagesForChatGroup(groupId, idOrder1688Item = null) {
    return api.get("/api/v1/chat-message/index", {
      params: {
        group_id: groupId,
        order_1688_item_id: idOrder1688Item ?? "",
      },
    });
  }

  addMessageToChatGroup(
    groupId,
    message,
    idOrder1688Item = null,
    uploadedImageBinary = null
  ) {
    if (uploadedImageBinary != null) {
      let formData = new FormData();
      formData.append("message", message);
      formData.append("group_id", groupId);
      formData.append("order_1688_item_id", idOrder1688Item ?? "");
      formData.append("message", message);
      formData.append("file[name]", "file");
      formData.append("file[title]", "image");
      formData.append("file", uploadedImageBinary);

      return api.post(
        "/api/v1/chat-message/create-with-image",
        // {
        // message: message,
        // group_id: groupId,
        // order_1688_item_id: idOrder1688Item ?? "",
        // file: {
        //   name: "file",
        //   title: "image",
        // },
        // }
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // onUploadProgress
        }
      );
    } else {
      return api.post("/api/v1/chat-message/create", {
        message: message,
        group_id: groupId,
        order_1688_item_id: idOrder1688Item ?? "",
      });
    }
  }

  getNewMessagesForChatGroup(groupId, lastId) {
    return api.get("/api/v1/chat-message/get-new-messages", {
      params: {
        group_id: groupId,
        last_id: lastId,
      },
    });
  }
}

export default new OrderService();
