<template>
  <div class="waiting-response">
    <div>
      <a-spin />
    </div>
    <div v-if="title !== null">
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Обработка запроса",
    },
  },
};
</script>
<style scoped>
.waiting-response {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(41, 46, 52, 0.6);

  /* Auto layout */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .waiting-response:after {
  content: "asdasd";
  color: #ffffff;
} */
</style>
